import { useState } from 'react';
import { Box } from '@mentimeter/ragnar-ui/box';
import { ModalRoot, ModalTrigger } from '@mentimeter/ragnar-ui/modal';
import { HelpModal } from './HelpModal';

export function HelpModalTrigger({ children }: { children: React.ReactNode }) {
  const [showHelp, setShowHelp] = useState(false);

  return (
    <Box id="help-icon" justifyContent="center">
      <ModalRoot open={showHelp} onOpenChange={setShowHelp}>
        <ModalTrigger>
          <Box id="help-thumbnail" width="100%">
            {children}
          </Box>
        </ModalTrigger>
        <HelpModal open={showHelp} />
      </ModalRoot>
    </Box>
  );
}
