import { MenuItemPaywallDataHandler } from '@mentimeter/paywalls/menu-item';
import { MenuItemInlineBadge } from 'features/menu-ui/item/components/MenuItemInlineBadge';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Clickable } from '@mentimeter/ragnar-ui/clickable';
import { Text } from '@mentimeter/ragnar-ui/text';
import type { MenuItemPaywallProps } from '../types';

export function MenuItemPaywall({
  id,
  text,
  icon,
  compact,
  title,
  description,
  trackingContext,
  trackingPlacement,
  upgradeContext,
  requiredUserFeatureType,
}: MenuItemPaywallProps) {
  const [tail, ...head] = text.split(' ').reverse();
  const prefix = head.reverse().join(' ');

  return (
    <MenuItemPaywallDataHandler
      enabled={true}
      requiredUserFeatureType={requiredUserFeatureType}
      title={title}
      description={description}
      trackingContext={trackingContext}
      trackingPlacement={trackingPlacement}
      upgradeContext={upgradeContext}
    >
      <Clickable
        id={id}
        px="space3"
        py="space2"
        flex="1 1 auto"
        display="inline-flex"
        flexDirection="row"
        alignItems="center"
        borderRadius="xl"
        extend={({ theme }) => ({
          '@media (hover: hover)': {
            ':hover': {
              opacity: 1,
              backgroundColor: theme.colors.infoWeakest,
            },
          },
          ':active': {
            opacity: 1,
            backgroundColor: theme.colors.infoWeak,
          },
        })}
      >
        {icon && (
          <Box display="inline-flex" mr="space2">
            {icon}
          </Box>
        )}
        <Text
          variant={compact ? 'dashboardBodyXs' : 'dashboardBodySm'}
          color="text"
        >
          {prefix && prefix + ' '}
          <Box display="inline-block">
            {tail + ' '}
            <Box display="inline-block">
              <MenuItemInlineBadge badgeType="upgrade" />
            </Box>
          </Box>
        </Text>
      </Clickable>
    </MenuItemPaywallDataHandler>
  );
}
