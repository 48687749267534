import React from 'react';
import type { BadgeTypes } from '@mentimeter/ragnar-ui/badge';
import { Badge } from '@mentimeter/ragnar-ui/badge';
import { Box } from '@mentimeter/ragnar-ui/box';

export function MenuItemInlineBadge({
  badgeType,
  text,
}: {
  badgeType: BadgeTypes;
  text?: string;
}) {
  return (
    <Box display="inline-block" ml={1} top="-1px">
      <Badge type={badgeType} text={text} compact maxHeight="23px" />
    </Box>
  );
}
