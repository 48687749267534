import { Box } from '@mentimeter/ragnar-ui/box';
import { Text } from '@mentimeter/ragnar-ui/text';

export function MenuItemSeparator({ text }: { text: string }) {
  return (
    <Box width="100%" px="space3" py="space2">
      <Text variant="dashboardBodyXs" color="textWeaker">
        {text}
      </Text>
    </Box>
  );
}
