import React, { useState } from 'react';
import { useOnce } from '@mentimeter/react-hooks';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Dark } from '@mentimeter/ragnar-ui/themes';
import {
  PopoverBody,
  PopoverContainer,
  PopoverFooter,
  PopoverHeader,
  PopoverRoot,
} from '@mentimeter/ragnar-ui/popover';
import { Text } from '@mentimeter/ragnar-ui/text';
import { PaywallPopoverTrigger } from '../../lib/triggers/PaywallPopoverTrigger';

export interface MenuItemPaywallPopoverProps {
  children: React.ReactNode;
  title: string;
  description: string;
  paywallActions: React.ReactNode;
  onOpen?: () => void;
}

export const MenuItemPaywallPopover = ({
  title,
  description,
  children,
  paywallActions,
  onOpen,
}: MenuItemPaywallPopoverProps) => {
  const [show, setShow] = useState(false);

  useOnce(show, () => {
    onOpen?.();
  });

  return (
    <PopoverRoot modal open={show} onOpenChange={setShow}>
      <PaywallPopoverTrigger trigger={() => setShow(true)}>
        {children}
      </PaywallPopoverTrigger>
      <PopoverContainer width={300}>
        <Dark>
          <Box bg="surface">
            <PopoverHeader>{title}</PopoverHeader>
            <PopoverBody>
              <Text>{description}</Text>
            </PopoverBody>
            <PopoverFooter>{paywallActions}</PopoverFooter>
          </Box>
        </Dark>
      </PopoverContainer>
    </PopoverRoot>
  );
};
