import React, { type ReactNode } from 'react';
import {
  TrackingContext,
  TrackingPlacement,
  type UserFeaturesTypeEnum,
} from '@mentimeter/http-clients';
import { useUser } from '@mentimeter/user';
import { gtmTrack } from '@mentimeter/google-tracking';
import { useWorkspace } from '@mentimeter/workspace-data-hooks';
import { trackUser } from '@api/tracking/client';
import { Button } from '@mentimeter/ragnar-ui/button';
import { RequestUpgradePopover } from '../../lib/request-upgrade';
import { Paywall } from '../../lib/paywall/Paywall';
import { trackOpenPaywall } from '../../lib/tracking/track-open-paywall';
import { RecommendPlanModal } from '../../lib/self-upgrade/RecommendPlanModal';
import { useRecommendPlanPaywall } from '../../lib/hooks/useRecommendPlanPaywall';
import ContactSalesModal from './ContactSalesModalRadix';
import { MenuItemPaywallPopover } from './MenuItemPaywallPopover';

interface MenuItemPaywallDataHandlerProps {
  enabled: boolean;
  requiredUserFeatureType: UserFeaturesTypeEnum;
  title: string;
  description: string;
  trackingContext: TrackingContext | undefined;
  trackingPlacement: string;
  upgradeContext: string;
  children: ReactNode;
}

export function MenuItemPaywallDataHandler({
  enabled,
  requiredUserFeatureType,
  trackingContext,
  trackingPlacement,
  upgradeContext,
  title,
  description,
  children,
}: MenuItemPaywallDataHandlerProps) {
  const { data: workspace } = useWorkspace();
  const { user } = useUser();

  const { showRecommendPlanPaywall } = useRecommendPlanPaywall();

  if (!user) return children;

  return (
    <Paywall
      enabled={enabled}
      requiredPlan={requiredUserFeatureType}
      requestUpgradeContent={
        <RequestUpgradePopover
          title={title}
          description={description}
          trackingContext={trackingContext}
          trackingPlacement={trackingPlacement}
        >
          {children}
        </RequestUpgradePopover>
      }
      selfUpgradeContent={
        showRecommendPlanPaywall ? (
          <RecommendPlanModal
            variant="shared"
            trackingContext={TrackingContext.SharedTemplates}
            trackingPlacement={TrackingPlacement.SharedTemplatesMenuItem}
            upgradeEvent="Plans from homeview"
          >
            {children}
          </RecommendPlanModal>
        ) : (
          <MenuItemPaywallPopover
            title={title}
            description={description}
            paywallActions={
              <>
                <ContactSalesModal user={user} upgradeContext={upgradeContext}>
                  <Button
                    key="contact-sales"
                    variant="secondary"
                    size="compact"
                    onClick={trackContactSales}
                  >
                    Contact sales
                  </Button>
                </ContactSalesModal>
                <Button
                  key="upgrade"
                  size="compact"
                  target="_blank"
                  variant="positive"
                  href="/plans"
                  onClick={trackClickUpgrade}
                >
                  Upgrade
                </Button>
              </>
            }
            onOpen={onOpenPaywall}
          >
            {children}
          </MenuItemPaywallPopover>
        )
      }
    >
      {children}
    </Paywall>
  );
  function onOpenPaywall() {
    trackOpenPaywall({
      trackingContext,
      trackingPlacement,
      upgradeType: 'Self upgrade',
    });
  }
  function trackClickUpgrade() {
    gtmTrack({
      event: 'clickInteraction',
      type: 'upgradeFromApp',
    });

    trackUser({
      event: 'Plans from homeview',
      properties: {
        context: upgradeContext,
        'current plan': user?.features.type ?? 'registered',
        'active team members': workspace?.memberStats?.active ?? 0,
      },
    });
  }

  function trackContactSales() {
    trackUser({
      event: 'Clicked contact sales',
      properties: {
        context: upgradeContext,
      },
    });
  }
}
