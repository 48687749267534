/* eslint-disable menti-react/filename-convention--jsx */
import { usePathname } from '@mentimeter/next-navigation';
import React from 'react';
import type { MenuItemProps, MenuItemPaywallProps } from './types';
import { MenuItem as MenuItemComponent } from './item/MenuItem';
import { MenuItemPaywall as MenuItemPaywallComponent } from './item/MenuItemPaywall';

export { MenuItemSeparator } from './item/components/MenuItemSeparator';
export { MenuItemSection } from './item/components/MenuItemSection';
export { MenuWrapper } from './components/MenuWrapper';
export { FooterItems } from './components/FooterItems';
export * from './tracking';

export const MenuItem = React.forwardRef<
  HTMLAnchorElement | HTMLButtonElement,
  MenuItemProps
>((props, _ref) => {
  const pathname = usePathname();

  const highlight =
    pathname === props.to ||
    (props.highlightPath && pathname.match(RegExp(props.highlightPath)));

  return <MenuItemComponent {...props} highlight={highlight} />;
});

export function MenuItemPaywall(props: MenuItemPaywallProps) {
  return <MenuItemPaywallComponent {...props} />;
}
